import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
function App() {
  // const callApi = () => {
  //   const data = {
  //     first_name: "test",
  //     last_name: "test",
  //     email: "tes4450@gmail.com",
  //     phone: "100055699",
  //     address: "test",
  //     street: "stest",
  //     town: "test",
  //     postcode: "11111111",
  //     country: "country name",
  //     marketing_optin: 1,
  //     td_title: "Football Own Goals franch",
  //     td_referrer: "https://mars23.ddev.site:8443/fr-be",
  //     td_description: "Football Own Goals",
  //     form_name: "EU_AF_Snickers_Football_2024",
  //     td_url:
  //       "https://mars23.ddev.site:8443/fr-be/own-goal?ajax_form=1&_wrapper_format=html&_wrapper_format=drupal_ajax",
  //     td_path: "https://mars23.ddev.site:8443",
  //     td_ip: "172.18.0.7",
  //     td_user_agent:
  //       "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
  //     td_language: "en-GB",
  //     td_browser: "Firefox",
  //     td_platform: "Windows",
  //     td_global_id: "hhjsdfu",
  //     td_client_id: "3889243872748",
  //     token: "JLkf2C1ipkZvNwWI/oNQA3I6c18eMaghUoImzgygsk",
  //   };
  //   axios
  //     .post(
  //       "https://stage.api.fuelyourfootie.kindsnacks.co.uk/user-validation",
  //       data
  //     )
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "https://dev.whiskas.co.nz/reverse-proxy/js";
  //   script.async = true;

  //   document.head.appendChild(script);

  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);
  return (
    <div>
      {/* <Helmet> */}
      {/* <script src="https://dev.whiskas.co.nz/reverse-proxy/js"></script> */}
      {/* </Helmet> */}
      <div id="header"></div>
      <div className="main-data">
        <h3>Campaign Form</h3>
      </div>
      <div id="footer"></div>
    </div>
  );
}

export default App;
